import { useCallback, useEffect, useState } from 'react';
import { useUserData } from 'src/hooks/common/useUserData';
import { JianInfo } from 'src/types/common/JianInfo';
import { JianSoshikiList } from 'src/types/common/JianSoshiki';
import { getJianinfo } from 'src/utilities/restApi/common/jianinfo';
import { getJianSoshiki } from 'src/utilities/restApi/kanri/jianSoshiki';

/**
 * jianInfo: 事案情報
 *   初期値: undefind
 *   データなし: null
 *   データあり: JianInfo
 */
export const useJianInfo = () => {
  const { userData } = useUserData();
  const [jianInfo, setJianInfo] = useState<JianInfo | null | undefined>(undefined);
  const [isError, setIsError] = useState(false);
  const [soshikis, setSoshikis] = useState<JianSoshikiList | undefined>(undefined);

  useEffect(() => {
    if (userData) {
      refreshJianInfo();
    }
  }, [userData]);

  const refreshJianInfo = () => {
    return getJianinfo()
      .then((response) => {
        if (response.data?.jianinfo) {
          setJianInfo({
            jianId: response.data.jianinfo.jianid,
            jianName: response.data.jianinfo.jianname,
            jianMode: response.data.jianinfo.jianmode,
            disasterTypeNumber: response.data.jianinfo.disaster_type_number,
            jianStart: response.data.jianinfo.jianstart,
            jianEnd: response.data.jianinfo.jianend ?? undefined,
            jianReset: response.data.jianinfo.jianreset ?? undefined,
          });

          getJianSoshiki({ jianid: response.data.jianinfo.jianid })
            .then((response) => {
              if (response.data?.soshiki_list) {
                setSoshikis(response.data.soshiki_list);
              }
            })
            .catch((error) => {
              throw error;
            });
        } else {
          setJianInfo(null);
          setSoshikis([]);
        }
      })
      .catch(() => setIsError(true));
  };

  const jianOngoing: () => boolean | undefined = useCallback(() => {
    let result: boolean | undefined = undefined;

    if (jianInfo !== undefined) {
      result = jianInfo !== null && jianInfo.jianEnd === undefined;
    }

    return result;
  }, [jianInfo]);

  const jianAllDone: () => boolean | undefined = useCallback(() => {
    let result: boolean | undefined = undefined;

    if (jianInfo !== undefined) {
      result = jianInfo === null || (jianInfo.jianEnd !== undefined && jianInfo.jianReset !== undefined);
    }

    return result;
  }, [jianInfo]);

  return { jianInfo, jianOngoing, jianAllDone, refreshJianInfo, isError, soshikis };
};
