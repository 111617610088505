import { FC, useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { FullCirclarProgress } from 'src/components/common/atoms/FullCirclarProgress';
import { useUserData } from 'src/hooks/common/useUserData';
import { hasPermission } from 'src/utilities/appUtil/commonUtil';

type ComponentProps = {
  notPermissionPath: string;
};

const loadingContent = <FullCirclarProgress />;

export const RequirePermission: FC<ComponentProps> = ({ notPermissionPath }) => {
  const location = useLocation();
  const { userData } = useUserData();
  const [permission, setPermission] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (userData) {
      const permission = hasPermission(location.pathname, userData.roleId);
      setPermission(permission);
    }
  }, [location, userData]);

  return permission !== undefined ? (
    permission ? (
      <Outlet />
    ) : (
      <Navigate to={notPermissionPath} state={{ from: location }} replace />
    )
  ) : (
    loadingContent
  );
};
