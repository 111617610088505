import { PageContainer } from 'src/components/common/atoms/PageContainer';
import { Illust04 } from 'src/components/common/atoms/images/Illust04';
import { ButtonPageTemplate } from 'src/components/shien/templates/ButtonPageTemplate';

export const NotFoundPage = () => {
  return (
    <PageContainer title="404 | mutual-assistance">
      <ButtonPageTemplate
        headline="ページが見つかりません"
        description="お探しのページは、移動または削除された可能性があります。"
      >
        <Illust04 />
      </ButtonPageTemplate>
    </PageContainer>
  );
};
