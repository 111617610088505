import { Button, CircularProgress, Stack, styled } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useLineIntegration } from 'src/components/shien/pages/SupporterProfilePage/hooks/useLineIntegration';

enum ButtonContent {
  NotLinked = '連携する',
  Linked = '連携解除',
}

enum LinkedStatus {
  NotLinked = '未連携',
  Linked = '連携中',
  Loading = '更新中',
}

const StyledButton = styled(Button)(({ theme }) => ({
  width: '88px',
  height: '24px',
  backgroundColor: theme.palette.uiStandard.lineGreen,
  '&:hover': {
    backgroundColor: theme.palette.uiStandard.lineGreen,
  },
}));

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  '.MuiCircularProgress-circle': {
    color: theme.palette.uiStandard.white, // テキストカラーに合わせる
  },
}));

type ComponentProps = {
  lineIntegrationErrorCallback?: () => void;
};

export const LineIntegrationRow: FC<ComponentProps> = ({ lineIntegrationErrorCallback }) => {
  const { isLinked, isImportedJichitaiData, loading, error: lineIntegrationError, link, unlink } = useLineIntegration();
  const [buttonContent, setButtonContent] = useState<ButtonContent | JSX.Element>();
  const [linkedStatus, setLinkedStatus] = useState<LinkedStatus>();

  useEffect(() => {
    if (lineIntegrationError && lineIntegrationErrorCallback) {
      lineIntegrationErrorCallback();
    }
  }, [lineIntegrationError]);

  // ボタンの表示設定
  useEffect(() => {
    if (!loading && isImportedJichitaiData && isLinked === true) {
      setButtonContent(ButtonContent.Linked);
      setLinkedStatus(LinkedStatus.Linked);
    } else if (!loading && isImportedJichitaiData && isLinked === false) {
      setButtonContent(ButtonContent.NotLinked);
      setLinkedStatus(LinkedStatus.NotLinked);
    } else {
      // loading=true || isLinked===undefined を想定
      setButtonContent(<StyledCircularProgress size={14.5} />); // テキストのlineHeightに合わせる
      setLinkedStatus(LinkedStatus.Loading);
    }
  }, [loading, isLinked, isImportedJichitaiData]);

  // 連携する or 連携解除ボタン押下時処理
  const handleClick = () => {
    if (isLinked === true) {
      unlink();
    } else if (isLinked === false) {
      // 連携処理 LINE認証画面へ遷移
      link();
    } else {
      // undefinedの場合何もしない(クリック不可のためこの分岐には入らない想定)
    }
  };

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      {linkedStatus}
      {/* CircularProgress表示中はクリック不可 */}
      <StyledButton
        variant="contained"
        onClick={handleClick}
        disabled={loading || isLinked === undefined || !isImportedJichitaiData}
      >
        {buttonContent}
      </StyledButton>
    </Stack>
  );
};
