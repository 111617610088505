import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { appConst } from 'src/constants/common';
import { useJichitaiData } from 'src/hooks/common/useJichitaiData';
import { useUserData } from 'src/hooks/common/useUserData';
import { postLineIntegration } from 'src/utilities/restApi/common/lineIntegration';
import { postUnlinkLineIntegration } from 'src/utilities/restApi/common/unlinkLineIntegration';
import { localStorageGetItem, localStorageRemoveItem, localStorageSetItem } from 'src/utilities/restApi/storageUtil';
import { ValueOf } from 'src/utilities/typeUtils';
import { generateLineAuthUrl } from '../logics/generateLineAuthUrl';

const LINE_LINK_TYPE = {
  link: 'link',
  unlink: 'unlink',
} as const;

// LINEプラットフォームから受け取ったアクセストークンの取得に使用される認可コード
const useRedirectParams = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get('code') ?? undefined;
  const state = searchParams.get('state') ?? undefined;
  return { code, state };
};

export const useLineIntegration = () => {
  // state
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const { userData, updateIsLinkedLineId } = useUserData();
  const { code, state } = useRedirectParams();
  const { jichitaiData } = useJichitaiData();

  //   useEffect
  const isInitialMount = useRef(true);
  useEffect(() => {
    // LINEの認証コードは1度しか使えないため、開発環境の2回目のuseEffectの実行を抑止
    // queryにcodeがある(=line認証画面から遷移してきた)場合,lineuseridをdbに登録する
    if (isInitialMount.current && code && state) {
      isInitialMount.current = false;
      // 認証コード取得に使用したstateとnonceを取得
      const storedState = localStorageGetItem<string | undefined>(appConst.STORAGE_KEY_LINE_STATE);
      const storedNonce = localStorageGetItem<string | undefined>(appConst.STORAGE_KEY_LINE_NONCE);
      const storedCodeVerifier = localStorageGetItem<string | undefined>(appConst.STORAGE_KEY_LINE_CODE_VERIFIER);
      const linkType = localStorageGetItem<ValueOf<typeof LINE_LINK_TYPE> | undefined>(
        appConst.STORAGE_KEY_LINE_LINK_TYPE
      );
      // 不要なので削除
      localStorageRemoveItem(appConst.STORAGE_KEY_LINE_STATE);
      localStorageRemoveItem(appConst.STORAGE_KEY_LINE_NONCE);
      localStorageRemoveItem(appConst.STORAGE_KEY_LINE_CODE_VERIFIER);
      localStorageRemoveItem(appConst.STORAGE_KEY_LINE_LINK_TYPE);

      if (storedState && storedNonce && storedCodeVerifier && state === storedState && linkType) {
        setLoading(true);
        setError(false);

        if (!userData) {
          setError(true);
          return;
        }

        const redirectPath = window.location.pathname;
        switch (linkType) {
          case 'link': // 連携
            // db更新処理
            postLineIntegration({ code, nonce: storedNonce, codeVerifier: storedCodeVerifier, redirectPath })
              .then(() => updateIsLinkedLineId(true)) // グローバルstateの更新(楽観的UI更新)
              .catch(() => setError(true))
              .finally(() => setLoading(false));
            break;
          case 'unlink': // 連携解除
            // db更新・連動アプリ権限取り消し処理
            postUnlinkLineIntegration({ code, nonce: storedNonce, codeVerifier: storedCodeVerifier, redirectPath })
              .then(() => updateIsLinkedLineId(false)) // グローバルstateの更新(楽観的UI更新)
              .catch(() => setError(true))
              .finally(() => setLoading(false));
            break;
        }
      } else {
        // リロードや直打ち時は何もしない
      }

      // クエリパラメータを削除
      navigate(window.location.pathname, { replace: true });
    }
  }, []);

  const { link, unlink } = (() => {
    const startAuth = async (channelId: string) => {
      // LINE認証画面からのリダイレクト先
      // NOTE:このイベント呼び出したページにリダイレクトする
      const redirectUri = window.location.origin + window.location.pathname;
      const { url, state, nonce, codeVerifier } = await generateLineAuthUrl(channelId, redirectUri);

      // リダイレクト先の検証用に保持
      localStorageSetItem(appConst.STORAGE_KEY_LINE_STATE, state);
      localStorageSetItem(appConst.STORAGE_KEY_LINE_NONCE, nonce);
      localStorageSetItem(appConst.STORAGE_KEY_LINE_CODE_VERIFIER, codeVerifier);

      window.location.href = url;
    };

    return {
      /**
       * 連携する押下時処理
       */
      link: async () => {
        if (jichitaiData?.channelId) {
          // 認証後の処理を「連携」に設定
          localStorageSetItem(appConst.STORAGE_KEY_LINE_LINK_TYPE, LINE_LINK_TYPE.link);
          await startAuth(jichitaiData.channelId);
        }
      },
      /**
       * 連携解除押下時処理
       */
      unlink: async () => {
        if (jichitaiData?.channelId) {
          // 認証後の処理を「連携解除」に設定
          localStorageSetItem(appConst.STORAGE_KEY_LINE_LINK_TYPE, LINE_LINK_TYPE.unlink);
          await startAuth(jichitaiData.channelId);
        }
      },
    };
  })();

  return {
    isLinked: userData?.isLinkedLineUserId,
    isImportedJichitaiData: jichitaiData !== undefined,
    loading,
    error,
    link,
    unlink,
  };
};
