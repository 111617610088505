import { useMsal } from '@azure/msal-react';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FullCirclarProgress } from 'src/components/common/atoms/FullCirclarProgress';
import { PageContainer } from 'src/components/common/atoms/PageContainer';
import { useInitialUrlByUser } from 'src/hooks/common/useInitialUrlByUser';
import { useNavigateSystemError } from 'src/hooks/common/useNavigateSystemError';
import { loginWithRedirect, popAfterPage } from 'src/utilities/auth/login';
import { useUserDetails } from 'src/utilities/azure/hooks/useUserDetails';
import { updateJotai } from 'src/utilities/azure/jotai';

export const AzureSigninPage = () => {
  const { accounts, inProgress } = useMsal();
  const { details: userDetails, error: userDetailsError } = useUserDetails(accounts[0]);
  const { navigateInfo: initialNavigateInfo, error: initialUrlError } = useInitialUrlByUser(userDetails);
  const navigate = useNavigate();
  const navigateSystemError = useNavigateSystemError();
  /** クリーンアップできないログイン処理がStrictMode時に2回実行されるのを防ぐためのフラグ */
  const isInitialMountRef = useRef(true);

  // ログイン状態に応じた処理
  useEffect(() => {
    if (accounts.length > 0) {
      // [認可済みユーザーあり]
      // useUserDetailsによりユーザー情報取得
    } else if (inProgress != 'none') {
      // [処理中]
    } else {
      // [認可なし]
      if (isInitialMountRef.current) {
        isInitialMountRef.current = false;

        loginWithRedirect('aadb2c').catch(() => {
          navigateSystemError();
        });
      }
    }
  }, [accounts, inProgress]);

  useEffect(() => {
    if (userDetails == null) return;
    updateJotai(userDetails);
  }, [userDetails]);

  useEffect(() => {
    if (initialNavigateInfo) {
      const afterLoginPage = popAfterPage();
      if (
        afterLoginPage === undefined ||
        (initialNavigateInfo.enforce &&
          // システム停止案内画面からのLINE連携後のコールバック時はクエリパラメーターを維持する
          initialNavigateInfo.path !== new URL(afterLoginPage, /* D/C */ window.location.origin).pathname)
      ) {
        navigate(initialNavigateInfo.path, { replace: true });
      } else {
        navigate(afterLoginPage, { replace: true });
      }
    }
  }, [initialNavigateInfo]);

  useEffect(() => {
    if (userDetailsError || initialUrlError) {
      navigateSystemError();
    }
  }, [userDetailsError, initialUrlError]);

  return (
    <PageContainer title="サインイン | mutual-assistance">
      <FullCirclarProgress />
    </PageContainer>
  );
};
