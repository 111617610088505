/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { SuccessResponse } from './common/types/responses';

export const restApi = (timeout: number | undefined = 27000) => {
  const axiosInstance = axios.create({
    baseURL: import.meta.env.VITE_API_BASE_URL,
    timeout,
  });

  axiosInstance.interceptors.request.use(async (config) => {
    console.info(`RestAPI [${config.method}]${config.url} START`);
    console.debug(JSON.stringify(config));
    return config;
  });
  axiosInstance.interceptors.response.use(
    (value: AxiosResponse<SuccessResponse<any>>) => {
      console.debug(JSON.stringify(value));
      console.debug(`RestAPI [${value.config.method}]${value.config.url} END`);
      return Promise.resolve(value);
    },
    (error: AxiosError) => {
      if (error.response) {
        console.error(JSON.stringify(error.response));
      } else if (error.code === axios.AxiosError.ERR_CANCELED) {
        // axios abort
        console.error(JSON.stringify(error));
        error.response = {
          data: { error: { reason: axios.AxiosError.ERR_CANCELED } },
          status: 499,
          statusText: 'Client Closed Request',
          headers: {},
          config: error.config,
        } as AxiosResponse;
      } else if (error.code === axios.AxiosError.ECONNABORTED) {
        // axios timeout
        console.error(JSON.stringify(error));
        error.response = {
          data: { error: { reason: axios.AxiosError.ECONNABORTED } },
          status: 408,
          statusText: 'Request Timeout',
          headers: {},
          config: error.config,
        } as AxiosResponse;
      } else {
        console.error(error);
        error.response = {
          data: { error: { reason: error.message } },
          status: 500,
          statusText: 'Internal Server Error',
          headers: {},
          config: error.config,
        } as AxiosResponse;
      }

      return Promise.reject(error);
    }
  );

  return {
    axiosInstance: axiosInstance,
    get: <T>(url: string, config?: AxiosRequestConfig): Promise<SuccessResponse<T>> => {
      return axiosInstance.get(url, config);
    },
    post: <T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<SuccessResponse<T>> => {
      return axiosInstance.post(url, data, config);
    },
    put: <T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<SuccessResponse<T>> => {
      return axiosInstance.put(url, data, config);
    },
    patch: <T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<SuccessResponse<T>> => {
      return axiosInstance.patch(url, data, config);
    },
    delete: <T>(url: string, config?: AxiosRequestConfig): Promise<SuccessResponse<T>> => {
      return axiosInstance.delete(url, config);
    },
  };
};
