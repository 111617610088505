import { AccountInfo, AuthenticationResult, BrowserAuthError, PublicClientApplication } from '@azure/msal-browser';
import { PublicClientApplication as PublicClientApplicationHolder } from 'src/utilities/azure/hooks/client';

const accessTokenRequest = {
  redirectUri: `${window.location.origin}/callback/azure`,
  scopes: ['openid'],
};

/**
 * @param {boolean} [force=false] 既に初期化済みの場合であっても実行する
 * @returns 初期化済みの{@link PublicClientApplication}
 */
export const initialize = (() => {
  // Closure States
  let initialized: null | Promise<PublicClientApplication> = null;

  // Implement
  return (force = false) => {
    if (force || initialized === null) {
      const pca = PublicClientApplicationHolder.getInstance();
      initialized = pca.initialize().then(() => pca);
      return initialized;
    } else {
      // 過去の呼び出しで実行中の初期化処理があればその処理の結果を返す
      return initialized;
    }
  };
})();

export const setActiveAccount = (account: AccountInfo | null) => {
  if (PublicClientApplicationHolder.hasInstance()) {
    PublicClientApplicationHolder.getInstance().setActiveAccount(account);
  }
};

export const acquireTokenSilent = async () => {
  return new Promise<AuthenticationResult | void>((resolve, reject) => {
    if (PublicClientApplicationHolder.hasInstance()) {
      PublicClientApplicationHolder.getInstance()
        .acquireTokenSilent(accessTokenRequest)
        .then((response) => {
          return resolve(response);
        })
        .catch((error) => {
          console.error(error);
          return reject(error);
        });
    } else {
      return resolve();
    }
  });
};

export const acquireTokenRedirect = async () => {
  return new Promise<void>((resolve, reject) => {
    if (PublicClientApplicationHolder.hasInstance()) {
      PublicClientApplicationHolder.getInstance()
        .acquireTokenRedirect(accessTokenRequest)
        .then(() => {
          return resolve();
        })
        .catch((error) => {
          if (error instanceof BrowserAuthError) {
            if (error.errorCode === 'interaction_in_progress') {
              // interaction_in_progressエラーは無視する
              console.log(error);
              return resolve();
            } else {
              return reject(error);
            }
          }
          return reject(error);
        });
    } else {
      return resolve();
    }
  });
};
