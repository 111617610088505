import { Box, Stack } from '@mui/material';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppSkeleton } from 'src/components/common/atoms/AppSkeleton';
import { PageContainer } from 'src/components/common/atoms/PageContainer';
import { ToggleButtonGreen } from 'src/components/shien/atoms/buttons/toggleButtons/ToggleButtonGreen';
import { ToggleButtonRed } from 'src/components/shien/atoms/buttons/toggleButtons/ToggleButtonRed';
import { Footer } from 'src/components/shien/atoms/Footer';
import { QuestionPageFrom } from 'src/components/shien/pages/possibility/common/types';
import { useSpCheckStatus } from 'src/components/shien/pages/possibility/SupportPossibleSubmissionPage/hooks/useSpCheckStatus';
import { useUpdateSpCheckStatus } from 'src/components/shien/pages/possibility/SupportPossibleSubmissionPage/hooks/useUpdateSpCheckStatus';
import { SpStatusId } from 'src/components/shien/pages/possibility/SupportPossibleSubmissionPage/types';
import { ButtonPageTemplate } from 'src/components/shien/templates/ButtonPageTemplate';
import { appConst } from 'src/constants/common';
import { useBackdrop } from 'src/hooks/common/backdrops';
import { useNavigateSystemError } from 'src/hooks/common/useNavigateSystemError';

export const SupportPossibleSubmissionPage = () => {
  const location = useLocation();
  const fromState = location.state as QuestionPageFrom;
  const from = fromState.from;

  const navigate = useNavigate();
  const navigateSystemError = useNavigateSystemError();
  const { selectingItem, setSelectingItem, loading: fetchLoading, error: fetchError } = useSpCheckStatus();
  const { updateSpStatus, loading: updateLoading } = useUpdateSpCheckStatus();
  const { setShowBackdrop } = useBackdrop();

  // エラーページに遷移
  useEffect(() => {
    if (fetchError) {
      navigateSystemError();
    }
  }, [fetchError]);

  // ローディングモーダル
  useEffect(() => {
    if (fetchLoading || updateLoading) {
      setShowBackdrop(true);
    } else {
      setShowBackdrop(false);
    }
  }, [fetchLoading, updateLoading]);

  // 〇 | △ ボタンクリックイベント
  const handleClick = async (selected: SpStatusId) => {
    switch (selected) {
      // 選択中のボタンは押下できない
      case selectingItem:
        return;
      // 〇ボタン押下
      case appConst.SPCHECKID.AVAILABLE: {
        const message = await updateSpStatus(appConst.SPCHECKID.AVAILABLE);
        if (message === 'success') {
          navigate('/app/shien/possibility/answer/possible', { state: { from } });
          setSelectingItem(appConst.SPCHECKID.AVAILABLE);
        } else {
          navigateSystemError();
        }
        break;
      }
      // △ボタン押下
      case appConst.SPCHECKID.UNABLE: {
        const message = await updateSpStatus(appConst.SPCHECKID.UNABLE);
        if (message === 'success') {
          navigate('/app/shien/possibility/answer/not-possible', { state: { from } });
          setSelectingItem(appConst.SPCHECKID.UNABLE);
        } else {
          navigateSystemError();
        }
        break;
      }
      default:
        throw new Error('unexcepted spcheckid !!!');
    }
  };

  return (
    <PageContainer title="協力確認 | mutual-assistance">
      <ButtonPageTemplate
        headline={
          <Stack alignItems="center">
            <Box>安否確認に</Box>
            <Box>ご協力いただけますか？</Box>
          </Stack>
        }
        description="支援を必要としている住民の安否確認に、ご協力をお願いします。"
        caption="安否確認は義務ではありません。ご自身やご家族の安全確保を優先してください。"
      >
        <Stack direction="row" spacing={2} sx={{ paddingBottom: '31px' }}>
          {fetchLoading ? (
            <AppSkeleton variant="card" width="164px" height="164px" />
          ) : (
            <ToggleButtonGreen
              selected={selectingItem === appConst.SPCHECKID.AVAILABLE}
              onClick={() => void handleClick(appConst.SPCHECKID.AVAILABLE)}
            >
              協力できる
            </ToggleButtonGreen>
          )}
          {fetchLoading ? (
            <AppSkeleton variant="card" width="164px" height="164px" />
          ) : (
            <ToggleButtonRed
              selected={selectingItem === appConst.SPCHECKID.UNABLE}
              onClick={() => void handleClick(appConst.SPCHECKID.UNABLE)}
            >
              協力できない
            </ToggleButtonRed>
          )}
        </Stack>
      </ButtonPageTemplate>
      {/* フッター */}
      {from !== 'EnsuringSafetyPage' && (
        <Box>
          <Footer
            mode="supporter"
            selected="協力の可否"
            disabled={selectingItem === appConst.SPCHECKID.UNABLE || fetchLoading}
          />
        </Box>
      )}
    </PageContainer>
  );
};
