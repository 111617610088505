import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Box,
  Button,
  Chip,
  Container,
  FormControlLabel,
  FormControlLabelProps,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  useRadioGroup,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { PageContainer } from 'src/components/common/atoms/PageContainer';
import { StackSpacer } from 'src/components/common/atoms/StackSpacer';
import { ConfirmDialog } from 'src/components/kanri/atoms/ConfirmDialog';
import { KanriPageContainer } from 'src/components/kanri/atoms/KanriPageContainer';
import { appConst } from 'src/constants/common';
import { useKanriPageBackdrop } from 'src/hooks/common/backdrops';
import { useNavigateSystemError } from 'src/hooks/common/useNavigateSystemError';
import { useUserData } from 'src/hooks/common/useUserData';
import { convertBool2String, convertString2Bool } from 'src/utilities/convertUtil';
import { getSystemStatus, patchSystemStatus } from 'src/utilities/restApi/kanri/systemStatus';

const AdisplayFlgFormControlLabel = (props: FormControlLabelProps & { adisplayFlg?: string }) => {
  const { adisplayFlg, ...formControlLabelProps } = props;
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === formControlLabelProps.value;
  }

  return (
    <Stack direction="row" alignItems="center">
      <FormControlLabel checked={checked} {...formControlLabelProps} />
      {adisplayFlg === formControlLabelProps.value && (
        <Chip icon={<CheckCircleIcon />} label="現在の設定" size="small" color="error" />
      )}
    </Stack>
  );
};

export const DisplayNonConsentingUsersPage = () => {
  const [adisplayflg, setAdisplayflg] = useState<string>('');
  const [value, setValue] = useState<string>('');
  const [dialogOpen, setDialogOpen] = useState(false);

  const theme = useTheme();
  const { userData } = useUserData();
  const { setShowBackdrop } = useKanriPageBackdrop();
  const navigateSystemError = useNavigateSystemError();

  useEffect(() => {
    refreshDisplay();
  }, [userData]);

  const refreshDisplay = () => {
    if (userData) {
      setShowBackdrop(true);
      getSystemStatus({ jichitaiid: userData.jichitaiId })
        .then((response) => {
          const value = convertBool2String(response?.data?.systemStatus.adisplayflg) ?? '';
          setValue(value);
          setAdisplayflg(value);
        })
        .catch(() => navigateSystemError())
        .finally(() => setShowBackdrop(false));
    } else {
      setShowBackdrop(true);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  return (
    <PageContainer title="未同意者表示設定 | mutual-assistance">
      <KanriPageContainer title="未同意者表示設定">
        <Container maxWidth="md" sx={{ px: { xs: '0px', sm: theme.spacing(2), md: theme.spacing(3) } }}>
          <Stack spacing={3}>
            <Paper variant="outlined" sx={{ p: 2 }}>
              <RadioGroup value={value} onChange={handleChange}>
                <AdisplayFlgFormControlLabel
                  adisplayFlg={adisplayflg}
                  value={appConst.STR_TRUE}
                  control={<Radio />}
                  label="外部提供に未同意の要支援者を表示する"
                />
                <AdisplayFlgFormControlLabel
                  adisplayFlg={adisplayflg}
                  value={appConst.STR_FALSE}
                  control={<Radio />}
                  label="外部提供同意に未同意の要支援者は表示しない"
                />
              </RadioGroup>
            </Paper>
            <Stack direction="row">
              <StackSpacer />
              <Box sx={{ width: { xs: '100%', sm: 'unset' } }}>
                <Button
                  variant="contained"
                  fullWidth={true}
                  disabled={adisplayflg === value}
                  onClick={() => setDialogOpen(true)}
                  sx={{ height: { xs: '40px', sm: 'unset' } }}
                >
                  変更する
                </Button>
              </Box>
            </Stack>
          </Stack>
        </Container>
      </KanriPageContainer>
      <ConfirmDialog
        message="変更してもよろしいですか？"
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onConfirm={() => {
          const newValue = convertString2Bool(value);
          if (userData && newValue !== undefined) {
            setDialogOpen(false);
            setShowBackdrop(true);
            patchSystemStatus({ jichitaiid: userData.jichitaiId, adisplayflg: newValue })
              .then(() =>
                // backdropの解除はrefreshDisplay内で実行
                refreshDisplay()
              )
              .catch(() => {
                setShowBackdrop(false);
                navigateSystemError();
              });
          }
        }}
      />
    </PageContainer>
  );
};
