import { AxiosError } from 'axios';
import { restApi } from 'src/utilities/restApi';
import {
  RequestPostLineIntegration,
  ResponsePostLineIntegration,
} from 'src/utilities/restApi/common/types/lineIntegration';
import { ErrorResponse, SuccessResponse } from 'src/utilities/restApi/common/types/responses';
import { getCommonHeaders } from 'src/utilities/restApi/utilities/getCommonHeader';

const url = '/line-integration';

export const postLineIntegration = async (data: RequestPostLineIntegration) => {
  const headers = { headers: await getCommonHeaders() };
  const config = { ...headers };

  return new Promise<SuccessResponse<ResponsePostLineIntegration>>((resolve, reject) => {
    restApi()
      .post<SuccessResponse<ResponsePostLineIntegration>>(url, data, config)
      .then((response) => resolve(response.data!))
      .catch((error: AxiosError<ErrorResponse>) => reject(error.response?.data));
  });
};
