import { AxiosError } from 'axios';
import { restApi } from 'src/utilities/restApi';
import { ErrorResponse, SuccessResponse } from 'src/utilities/restApi/common/types/responses';
import {
  RequestPostUnlinkLineIntegration,
  ResponsePostUnlinkLineIntegration,
} from 'src/utilities/restApi/common/types/unlinkLineIntegration';
import { getCommonHeaders } from 'src/utilities/restApi/utilities/getCommonHeader';

const url = '/unlink-line-integration';

export const postUnlinkLineIntegration = async (data: RequestPostUnlinkLineIntegration) => {
  const headers = { headers: await getCommonHeaders() };
  const config = { ...headers };

  return new Promise<SuccessResponse<ResponsePostUnlinkLineIntegration>>((resolve, reject) => {
    restApi()
      .post<SuccessResponse<ResponsePostUnlinkLineIntegration>>(url, data, config)
      .then((response) => resolve(response.data!))
      .catch((error: AxiosError<ErrorResponse>) => reject(error.response?.data));
  });
};
