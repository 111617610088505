export const standardColors = {
  black: '#000000',
  white: '#ffffff',
  gray50: '#4b4b4b',
  gray40: '#999999',
  gray30: '#bfbfbf',
  gray25: '#d9d9d9',
  gray10: '#f7f7f7',
  green60: '#2e5639',
  green50: '#147942',
  green40: '#458256',
  green25: '#b3d0c0',
  green20: '#deefe6',
  yellow60: '#98700d',
  yellow50: '#eab531',
  yellow25: '#e0cd9d',
  yellow20: '#f7ebcd',
  red50: '#b33d35',
  red25: '#e5b4b4',
  red20: '#fae3e3',
  blue50: '#007aff',
  lineGreen: '#06C755', // LINEボタン用
};
