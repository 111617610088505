import AddIcon from '@mui/icons-material/Add';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import EditIcon from '@mui/icons-material/Edit';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Button, colors, IconButton, Stack, Tooltip } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, GridSortModel } from '@mui/x-data-grid';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useInterval } from 'react-use';
import { PageContainer } from 'src/components/common/atoms/PageContainer';
import { PrsActionChip } from 'src/components/common/atoms/PrsActionChip';
import { KanriPageContainer } from 'src/components/kanri/atoms/KanriPageContainer';
import { StatusChip } from 'src/components/kanri/atoms/StatusChip';
import { AssignSupportersSoshikiDialog } from 'src/components/kanri/pages/jichitai/AssignSupportSoshikiPage/index';
import { AssignPageInfo } from 'src/components/kanri/pages/jichitai/AssignSupportSoshikiPage/types';
import { SearchConditionField } from 'src/components/kanri/pages/jichitai/SearchSupportRecipientsPage/SearchConditionField';
import { SearchConditions } from 'src/components/kanri/pages/jichitai/SearchSupportRecipientsPage/SearchConditionField/types';
import { SupportRecipientDialog } from 'src/components/kanri/pages/jichitai/SearchSupportRecipientsPage/SupportRecipientDialog';
import {
  GridRow,
  GridRowSupporter,
  SearchType,
} from 'src/components/kanri/pages/jichitai/SearchSupportRecipientsPage/types';
import { useQuery } from 'src/components/kanri/pages/jichitai/SearchSupportRecipientsPage/useQuery';
import { appConst } from 'src/constants/common';
import { SnackbarMessageId, snackbarMessages } from 'src/constants/snackbarMessages';
import { useJianInfo } from 'src/hooks/common/useJianInfo';
import { useNavigateSystemError } from 'src/hooks/common/useNavigateSystemError';
import { useUserData } from 'src/hooks/common/useUserData';
import { StatusColor } from 'src/types/common/statusColor';
import { getSystemStatus } from 'src/utilities/restApi/kanri/systemStatus';

const iconColorGrey = colors.grey[700];

export const SearchSupportRecipientsPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigateSystemError = useNavigateSystemError();
  const { userData } = useUserData();
  const { jianOngoing } = useJianInfo();

  // states
  const [searchType, setSearchType] = useState<SearchType>('search');
  const [selectedPrsId, setSelectedPrsId] = useState<string>();
  const [supportRecipientDialogOpen, setSupportRecipientDialogOpen] = useState(false);
  const [pageInfoCallAssign, setPageInfoCallAssign] = useState<AssignPageInfo>();
  const [assignmentOpen, setAssignmentOpen] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 20,
  });
  const [searchConditions, setSearchConditions] = useState<SearchConditions>({
    simple: {
      prsStatusId: '',
      soshikiList: [],
      registeredMemo: appConst.STR_EMPTY,
      agreement: appConst.STR_EMPTY,
    },
    advanced: undefined,
  });
  const [sortModel, setSortModel] = useState<GridSortModel | undefined>([{ field: 'name', sort: 'asc' }]);
  const [adisplayFlg, setAdisplayFlg] = useState<boolean>(false);

  // custom hooks
  const {
    isLoading,
    rows,
    pageInfo,
    error: searchError,
    search,
  } = useQuery({ paginationModel, searchConditions, sortModel });

  // intervalによる画面更新
  useInterval(
    () => {
      setSearchType('refresh');
      search('system');
    },
    isLoading ? null : appConst.POLLING_INTERVAL
  );

  const rowCountRef = useRef(pageInfo?.totalRowCount || 0);

  const rowCount = useMemo(() => {
    if (pageInfo?.totalRowCount !== undefined) {
      rowCountRef.current = pageInfo.totalRowCount;
    }
    return rowCountRef.current;
  }, [pageInfo?.totalRowCount]);

  // functions
  const detailButtonOnClick = (prsId: string) => {
    setSelectedPrsId(prsId);
    setSupportRecipientDialogOpen(true);
  };

  const changeAssignmentButtonOnClick = (prsId: string, prsName: string, supporterIds: GridRowSupporter[]) => {
    setPageInfoCallAssign({
      spIds: supporterIds.map((x) => x.spId ?? '').filter((x) => x != ''),
      soshikiIds: supporterIds.map((x) => x.soshikiId ?? '').filter((x) => x != ''),
      prsIds: [prsId],
      prsNames: prsName,
    });
    setAssignmentOpen(true);
  };

  const assignmentDialogClose = () => {
    setPageInfoCallAssign(undefined);
    setAssignmentOpen(false);
    setSearchType('refresh');
    search('system');
  };

  const onSortModelChange = (model: GridSortModel) => {
    if (isLoading !== 'user') {
      setSortModel(model);
    }
  };

  const columns: GridColDef<GridRow>[] = useMemo(
    () => [
      { field: 'id', headerName: 'ID', width: 90 },
      {
        field: 'name',
        headerName: '氏名',
        width: 150,
        disableColumnMenu: true,
        sortable: true,
      },
      {
        field: 'memo',
        headerName: 'メモ',
        width: 80,
        minWidth: 80,
        disableColumnMenu: true,
        sortable: true,
        renderCell: (params: GridRenderCellParams<GridRow, string>) => {
          return params.row.prsMemo ? (
            <Stack height="100%" alignItems="center" justifyContent="center">
              <Tooltip title={params.row.prsMemo} arrow>
                <MenuBookIcon fontSize="small" htmlColor={iconColorGrey} />
              </Tooltip>
            </Stack>
          ) : (
            appConst.STR_EMPTY
          );
        },
      },
      {
        field: 'agreement',
        headerName: '同意',
        renderHeader: () => (
          <Tooltip title="同意済は空白で表示されます" placement="top" arrow>
            <Box>同意</Box>
          </Tooltip>
        ),
        width: 80,
        minWidth: 80,
        disableColumnMenu: true,
        sortable: true,
        renderCell: (params: GridRenderCellParams<GridRow, boolean>) =>
          params.value ? appConst.STR_EMPTY : appConst.LABEL_UNAGREED,
      },
      {
        field: 'prsstatus',
        headerName: '安否状況',
        width: 200,
        disableColumnMenu: true,
        sortable: true,
        renderCell: (params: GridRenderCellParams<GridRow, boolean>) => {
          const prsActionType =
            params.row.lastVisitedAt && params.row.lastVisitedAt === params.row.lastPrsStatusUpdatedAt
              ? 'visit'
              : params.row.lastEvacuatedAt && params.row.lastEvacuatedAt === params.row.lastPrsStatusUpdatedAt
              ? 'evacuate'
              : undefined;
          return (
            <Stack height="100%" direction="row" alignItems="center" justifyContent="space-between">
              <Box>
                <StatusChip
                  prsStatusId={params.row.prsStatusId}
                  label={params.row.prsStatusName}
                  color={params.row.prsStatusColor as StatusColor}
                />
              </Box>
              {prsActionType && <PrsActionChip prsActionType={prsActionType} />}
            </Stack>
          );
        },
      },
      {
        field: 'supporters',
        headerName: '支援者 / 支援組織',
        width: 300,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params: GridRenderCellParams<GridRow, GridRowSupporter[]>) =>
          params.value && params.value.length > 0 ? (
            <Stack height="100%" direction="row" alignItems="center" justifyContent="space-between">
              <Box sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                {params.value?.map((x) => x.name ?? x.soshikiName).join(', ')}
              </Box>
              {jianOngoing() && (
                <Tooltip title="支援者を編集" arrow>
                  <IconButton
                    size="small"
                    onClick={() =>
                      changeAssignmentButtonOnClick(params.row.prsId, params.row.name, params.row.supporters)
                    }
                  >
                    <EditIcon fontSize="small" htmlColor={iconColorGrey} />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          ) : (
            <Button
              fullWidth
              startIcon={<AddIcon />}
              onClick={() => changeAssignmentButtonOnClick(params.row.prsId, params.row.name, params.row.supporters)}
              disabled={!jianOngoing()}
            >
              支援者割当
            </Button>
          ),
      },
      {
        field: 'spcheck',
        renderHeader: () => (
          <>
            支援組織を除く
            <br />
            支援可否
          </>
        ),
        width: 150,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params: GridRenderCellParams<GridRow, GridRowSupporter[]>) => {
          // NOTE: 支援組織は考慮せず、直接の支援者の支援可否を判断する
          /**
           * 支援者Ａ　　支援者Ｂ  表示
           * 不明　　　　不明　　　不明
           * 不明　　　　対応不可　不明
           * 不明　　　　対応可　　対応可
           * 対応不可　　不明　　　不明
           * 対応不可　　対応不可　対応不可
           * 対応不可　　対応可　　対応可
           * 対応可　　　不明　　　対応可
           * 対応可　　　対応不可　対応可
           * 対応可　　　対応可　　対応可
           */
          const spCheckList = params.row.supporters
            .filter((x) => x.spCheckId)
            .map((x) => ({
              spCheckId: x.spCheckId,
              spCheckName: x.spCheckStatus,
            }));

          // AVAILABLEを含む場合AVAILABLEを設定、NOT_ANSWERDを含む場合NOT_ANSWERDを設定、それ以外はUNABLEを設定し、spCheckIdとspCheckNameを返す
          const availability = spCheckList.some((x) => x.spCheckId === appConst.SPCHECKID.AVAILABLE)
            ? {
                ...spCheckList.find((x) => x.spCheckId === appConst.SPCHECKID.AVAILABLE),
                icon: <CircleOutlinedIcon fontSize="small" color="success" />,
              }
            : spCheckList.some((x) => x.spCheckId === appConst.SPCHECKID.NOT_ANSWERD)
            ? {
                ...spCheckList.find((x) => x.spCheckId === appConst.SPCHECKID.NOT_ANSWERD),
                icon: <QuestionMarkIcon fontSize="small" htmlColor={iconColorGrey} />,
              }
            : spCheckList.some((x) => x.spCheckId === appConst.SPCHECKID.UNABLE)
            ? {
                ...spCheckList.find((x) => x.spCheckId === appConst.SPCHECKID.UNABLE),
                icon: <ChangeHistoryIcon fontSize="small" color="error" />,
              }
            : {
                spCheckName: undefined,
                icon: <RemoveIcon htmlColor={iconColorGrey} />,
              };

          return (
            <Stack height="100%" direction="row" alignItems="center">
              {availability.icon}
              {availability.spCheckName}
            </Stack>
          );
        },
      },
      {
        field: 'soshiki',
        headerName: '所属地域',
        width: 200,
        minWidth: 200,
        flex: 1,
        disableColumnMenu: true,
        sortable: true,
        renderCell: (params: GridRenderCellParams<GridRow, GridRowSupporter[]>) => params.row.soshikiNames,
      },
      {
        field: 'action',
        headerName: appConst.STR_EMPTY,
        disableColumnMenu: true,
        sortable: false,
        width: 90,
        minWidth: 90,
        maxWidth: 90,
        renderCell: (params: GridRenderCellParams<GridRow, GridRowSupporter[]>) => (
          <Button
            fullWidth
            onClick={() => {
              detailButtonOnClick(params.row.prsId);
            }}
          >
            詳細
          </Button>
        ),
      },
    ],
    [jianOngoing()]
  );

  // useEffect
  useEffect(() => {
    if (userData) {
      getSystemStatus({ jichitaiid: userData.jichitaiId })
        .then((response) => {
          if (response.data) {
            setAdisplayFlg(response.data.systemStatus.adisplayflg);
          }
        })
        .catch(() => navigateSystemError());
    }
  }, [userData]);

  useEffect(() => {
    if (searchError.isError) {
      if (![axios.AxiosError.ERR_CANCELED, axios.AxiosError.ECONNABORTED].includes(searchError.reason ?? '')) {
        navigateSystemError();
      } else if (searchError.reason === axios.AxiosError.ECONNABORTED) {
        const snackbarMessage = snackbarMessages[SnackbarMessageId.SearchTimeOut];

        if (snackbarMessage) {
          enqueueSnackbar(snackbarMessage.message, {
            variant: snackbarMessage.variant,
          });
        }
      }
    }
  }, [searchError]);

  return (
    <PageContainer title="要支援者一覧 | mutual-assistance">
      <KanriPageContainer title="要支援者（対象者）一覧">
        <Stack spacing={2} sx={{ overflow: 'auto', height: '100%' }}>
          <SearchConditionField
            adisplayFlg={adisplayFlg}
            isLoading={isLoading}
            searchButtonOnClick={(searchConditions) => {
              setSearchConditions(searchConditions);
              setSearchType('search');
            }}
          />
          <Stack flexGrow={1} sx={{ overflow: 'auto' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              rowCount={rowCount}
              loading={!!isLoading}
              disableRowSelectionOnClick
              paginationModel={paginationModel}
              paginationMode="server"
              onPaginationModelChange={setPaginationModel}
              sortModel={sortModel}
              sortingMode="server"
              onSortModelChange={onSortModelChange}
              columnVisibilityModel={{ id: false, agreement: adisplayFlg }}
              slotProps={{
                loadingOverlay: {
                  variant: searchType === 'search' ? 'circular-progress' : 'linear-progress',
                  noRowsVariant: 'skeleton',
                  sx: searchType === 'search' ? {} : { backgroundColor: 'transparent' },
                },
                ...(isLoading === 'user'
                  ? {
                      pagination: {
                        backIconButtonProps: {
                          disabled: true,
                        },
                        nextIconButtonProps: {
                          disabled: true,
                        },
                      },
                    }
                  : {}),
              }}
            />
          </Stack>
        </Stack>
      </KanriPageContainer>
      <SupportRecipientDialog
        prsId={selectedPrsId}
        open={supportRecipientDialogOpen}
        onClose={() => {
          setSupportRecipientDialogOpen(false);
          setSelectedPrsId(undefined);
        }}
        onError={() => navigateSystemError()}
      />
      <AssignSupportersSoshikiDialog
        pageInfoCallAssign={pageInfoCallAssign}
        isOpen={assignmentOpen}
        onClose={() => assignmentDialogClose()}
      />
    </PageContainer>
  );
};
