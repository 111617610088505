import { Link, Stack, useTheme } from '@mui/material';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppSkeleton } from 'src/components/common/atoms/AppSkeleton';
import { PageContainer } from 'src/components/common/atoms/PageContainer';
import { AppList } from 'src/components/shien/atoms/AppList';
import { ConfirmDialog } from 'src/components/shien/atoms/dialogs/ConfirmDialog';
import { PrsContext } from 'src/components/shien/pages/prs/PrsContextTemplate';
import { HeaderWithBackButtonTemplate } from 'src/components/shien/templates/HeaderWithBackButtonTemplate';
import { sexCodes } from 'src/constants/common';
import { useNavigateSystemError } from 'src/hooks/common/useNavigateSystemError';
import { useOtherSupportersSectionDataList } from 'src/hooks/common/useOtherSupportersSectionDataList';
import { usePlannedShelterSectionDataList } from 'src/hooks/common/usePlannedShelterSectionDataList';
import { usePrsSectionDataList } from 'src/hooks/common/usePrsSectionDataList';
import { useSoshikiLeadersSectionDataList } from 'src/hooks/common/useSoshikiLeadersSectionDataList';
import { useUserData } from 'src/hooks/common/useUserData';
import { calculateCurrentAge } from 'src/utilities/dateUtil';
import { patchPrsDetail } from 'src/utilities/restApi/common/prsDetail';

type PageData = {
  prsId: string;
  prsName: string;
  sexLabel: string;
  ageLabel: string;
};

export const PrsDetailPage = () => {
  // context
  const { prs, refreshData, initialized: prsContextInitialized } = useContext(PrsContext);

  // state
  const { userData } = useUserData();
  const [pageData, setPageData] = useState<PageData>();
  const [confirmCallDialogOpen, setConfirmCallDialogOpen] = useState(false);
  const [confirmCallDialogMessage, setConfirmCallDialogMessage] = useState<ReactNode>('');
  const [callPhoneNumber, setCallPhoneNumber] = useState<string>();

  const theme = useTheme();
  const navigate = useNavigate();
  const navigateSystemError = useNavigateSystemError();

  const { sectionDataList: prsSectionDataList } = usePrsSectionDataList((telNo, message) => {
    setCallPhoneNumber(telNo);
    setConfirmCallDialogMessage(message);
    setConfirmCallDialogOpen(true);
  }, prs);
  const { sectionDataList: shelterSectionDataList, getSectionDataList: getPlannedShelterSectionDataList } =
    usePlannedShelterSectionDataList();
  const { sectionDataList: otherSupportersSectionDataList, getSectionDataList: getOtherSupportersSectionDataList } =
    useOtherSupportersSectionDataList('他の支援者');
  const { sectionDataList: shienSoshikiLeadersSectionDataList, getSectionDataList: getShienSoshikiLeadersDataList } =
    useSoshikiLeadersSectionDataList();

  useEffect(() => {
    if (userData && prs && prsContextInitialized) {
      const age = calculateCurrentAge(prs.birthday);
      const pageData: PageData = {
        prsId: prs.prsId,
        prsName: `${prs.fName} ${prs.sName}`,
        sexLabel: sexCodes.find((x) => x.code === prs.sex)?.Label ?? '',
        ageLabel: age ? `${age}歳` : '',
      };

      setPageData(pageData);

      getPlannedShelterSectionDataList(prs.plannedShelters);

      const spIds: string[] = prs.shienKankei
        .filter((x) => x.spId && userData.userId !== x.spId)
        .map((x) => x.spId as string);

      getOtherSupportersSectionDataList(spIds, (telNo, message) => {
        setCallPhoneNumber(telNo);
        setConfirmCallDialogMessage(message);
        setConfirmCallDialogOpen(true);
      }).catch(() => navigateSystemError());

      const soshikiIds: string[] = prs.shienKankei.filter((x) => x.soshikiId).map((x) => x.soshikiId as string);
      getShienSoshikiLeadersDataList(soshikiIds, (telNo, message) => {
        setCallPhoneNumber(telNo);
        setConfirmCallDialogMessage(message);
        setConfirmCallDialogOpen(true);
      }).catch(() => navigateSystemError());
    }
  }, [userData, prs, prsContextInitialized]);

  return (
    <PageContainer title="要支援者基本情報 | mutual-assistance">
      <HeaderWithBackButtonTemplate
        headlineText={prsContextInitialized && pageData ? `${pageData.prsName}` : <AppSkeleton width="200px" />}
        lowerText={
          prsContextInitialized && pageData ? (
            `${pageData.sexLabel} ${pageData.ageLabel}`
          ) : (
            <AppSkeleton width="100px" />
          )
        }
        onBackClick={() => navigate('/app/shien/prs/summary')}
      >
        <Stack sx={{ height: '100%', backgroundColor: theme.palette.uiStandard.gray10 }}>
          <AppList
            sectionDataList={[
              ...prsSectionDataList,
              ...shelterSectionDataList,
              ...otherSupportersSectionDataList,
              ...shienSoshikiLeadersSectionDataList,
            ]}
          />
        </Stack>
      </HeaderWithBackButtonTemplate>
      <ConfirmDialog
        title="電話をかける"
        message={
          <>
            {confirmCallDialogMessage}
            <br />
            {callPhoneNumber}
          </>
        }
        positiveButtonProps={Object.assign(
          { children: 'かける' },
          callPhoneNumber && { component: Link, href: `tel:${callPhoneNumber}` }
        )}
        negativeButtonProps={{ children: 'かけない' }}
        open={confirmCallDialogOpen}
        onClose={() => {
          setConfirmCallDialogOpen(false);
          setCallPhoneNumber(undefined);
        }}
        onConfirm={() => {
          setConfirmCallDialogOpen(false);
          if (pageData?.prsId && prs?.telNo === callPhoneNumber) {
            patchPrsDetail({ prsid: pageData.prsId, lastcalledat: true })
              .then(() => {
                refreshData();
              })
              .catch(() => navigateSystemError());
          }

          setCallPhoneNumber(undefined);
        }}
      />
    </PageContainer>
  );
};
