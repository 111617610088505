import { AxiosError, AxiosRequestConfig } from 'axios';
import { restApi } from 'src/utilities/restApi';
import { ErrorResponse, SuccessResponse } from 'src/utilities/restApi/common/types/responses';
import { RequestSearchUsers, ResponseSearchUsers } from 'src/utilities/restApi/kanri/types/searchUsers';
import { getCommonHeaders } from 'src/utilities/restApi/utilities/getCommonHeader';

export const searchUsers = async (data: RequestSearchUsers, controller?: AbortController) => {
  const headers = { headers: await getCommonHeaders() };
  const config: AxiosRequestConfig = { ...headers, signal: controller?.signal };

  return new Promise<SuccessResponse<ResponseSearchUsers>>((resolve, reject) => {
    restApi()
      .post<SuccessResponse<ResponseSearchUsers>>('/search/users', data, config)
      .then((response) => {
        resolve(response.data!);
      })
      .catch((error: AxiosError<ErrorResponse>) => {
        if (error.message === 'canceled') {
          return reject('canceled');
        } else {
          return reject(error.response?.data);
        }
      });
  });
};
