import { atom, createStore } from 'jotai';
import { atomWithStorage, RESET } from 'jotai/utils';
import { appConst } from 'src/constants/common';
import { createLocalStorage } from 'src/jotai/jotiaUtil';
import { JichitaiData } from 'src/types/common/JichitaiData';
import { UserData } from 'src/types/common/UserData';
import { LoginKind } from 'src/utilities/auth/loginKind';

export const globalDataStore = createStore();

const loginKindStorage = createLocalStorage<LoginKind | undefined>();
export const loginKindAtom = atomWithStorage<LoginKind | undefined>(
  appConst.STORAGE_KEY_LOGIN_KIND,
  undefined,
  loginKindStorage,
  {
    getOnInit: true,
  }
);

const afterLoginPageStorage = createLocalStorage<string | undefined>();
export const afterLoginPageAtom = atomWithStorage<string | undefined>(
  appConst.STORAGE_KEY_AFTER_LOGIN_PAGE,
  undefined,
  afterLoginPageStorage,
  {
    getOnInit: true,
  }
);

const userIdStorage = createLocalStorage<string | undefined>();
export const userIdAtom = atomWithStorage<string | undefined>(appConst.STORAGE_KEY_USERID, undefined, userIdStorage, {
  getOnInit: true,
});

export const userDataAtom = atom<UserData | undefined>(undefined);

export const jichitaiDataAtom = atom<JichitaiData | undefined>(undefined);

export const showBackdropAtom = atom(false);
export const showKanriPageBackdropAtom = atom(false);

/**
 * ユーザー情報を初期化する (ログアウト時など用)
 */
export const resetCredentialJotais = () => {
  globalDataStore.set(loginKindAtom, RESET);
  globalDataStore.set(afterLoginPageAtom, RESET);
  globalDataStore.set(userIdAtom, RESET);
  globalDataStore.set(userDataAtom, undefined);
  globalDataStore.set(jichitaiDataAtom, undefined);
};
