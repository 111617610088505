import React from 'react';

/**
 * @param process クリーンアップ関数が実行されたときに発火する{@link AbortController}を用いる処理
 * @returns `EffectCallback` (`useEffect()`の第一引数)
 * @example
 * useEffect(
 *   withDispose((abortController) => {
 *     fetchUser(abortController);
 *   }),
 *   []
 * );
 */
export const withDispose = (process: (abortController: AbortController) => void): React.EffectCallback => {
  const effectCallback = () => {
    const abortController = new AbortController();
    process(abortController);
    return () => abortController.abort('cleanup');
  };
  return effectCallback;
};
