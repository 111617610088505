import MenuBookIcon from '@mui/icons-material/MenuBook';
import { Box, Button, colors, Stack, Tooltip, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, GridRowSelectionModel, GridSortModel } from '@mui/x-data-grid';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageContainer } from 'src/components/common/atoms/PageContainer';
import { PrsActionChip } from 'src/components/common/atoms/PrsActionChip';
import { StackSpacer } from 'src/components/common/atoms/StackSpacer';
import { KanriPageContainer } from 'src/components/kanri/atoms/KanriPageContainer';
import { StatusChip } from 'src/components/kanri/atoms/StatusChip';
import { AssignSupportersSoshikiDialog } from 'src/components/kanri/pages/jichitai/AssignSupportSoshikiPage/index';
import { AssignPageInfo } from 'src/components/kanri/pages/jichitai/AssignSupportSoshikiPage/types';
import { SupportRecipientDialog } from 'src/components/kanri/pages/jichitai/SearchSupportRecipientsPage/SupportRecipientDialog';
import { PrsGridRow } from 'src/components/kanri/pages/jichitai/SelectSupportRecipientsPage/types';
import { useQuery } from 'src/components/kanri/pages/jichitai/SelectSupportRecipientsPage/useQuery';
import { appConst } from 'src/constants/common';
import { SnackbarMessageId, snackbarMessages } from 'src/constants/snackbarMessages';
import { useNavigateSystemError } from 'src/hooks/common/useNavigateSystemError';
import { useUserData } from 'src/hooks/common/useUserData';
import { routerPaths } from 'src/routes/path';
import { StatusColor } from 'src/types/common/statusColor';
import { getSystemStatus } from 'src/utilities/restApi/kanri/systemStatus';

const iconColorGrey = colors.grey[700];

export const SelectSupportRecipientsPage = () => {
  const navigateSystemError = useNavigateSystemError();
  const location = useLocation() as unknown as { state: { spId: string } };
  const [spId, setSpId] = useState<string | null>();
  useEffect(() => {
    if (location.state && location.state.spId) {
      setSpId(location.state.spId);
    } else {
      navigateSystemError();
    }
  }, [location]);
  return <>{spId ? <SelectSupportRecipientsComponent spId={spId} /> : <></>}</>;
};

const SelectSupportRecipientsComponent = ({ spId }: { spId: string }) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const navigateSystemError = useNavigateSystemError();
  const { userData } = useUserData();

  // states
  const [selectedPrsId, setSelectedPrsId] = useState<string>();
  const [supportRecipientDialogOpen, setSupportRecipientDialogOpen] = useState(false);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });
  const [sortModel, setSortModel] = useState<GridSortModel | undefined>([{ field: 'name', sort: 'asc' }]);
  const [adisplayFlg, setAdisplayFlg] = useState<boolean>(false);
  const [pageInfoCallAssign, setPageInfoCallAssign] = useState<AssignPageInfo>();
  const [assignmentOpen, setAssignmentOpen] = useState(false);

  // custom hooks
  const { isLoading, rows, pageInfo, error: searchError } = useQuery({ paginationModel, spId, sortModel });

  const rowCountRef = useRef(pageInfo?.totalRowCount || 0);

  const rowCount = useMemo(() => {
    if (pageInfo?.totalRowCount !== undefined) {
      rowCountRef.current = pageInfo.totalRowCount;
    }
    return rowCountRef.current;
  }, [pageInfo?.totalRowCount]);

  // functions
  const detailButtonOnClick = (prsId: string) => {
    setSelectedPrsId(prsId);
    setSupportRecipientDialogOpen(true);
  };

  const onSortModelChange = (model: GridSortModel) => {
    if (isLoading !== 'user') {
      setSortModel(model);
    }
  };

  const changeAssignmentButtonOnClick = () => {
    const selectedRows = rows.filter((row) => selectionModel.includes(row.prsId));
    const spids: string[] = [];
    const soshikiids: string[] = [];
    selectedRows.forEach((row) => {
      row.spidkankeis.forEach((value) => {
        if (value.spId) {
          spids.push(value.spId);
        }
      });

      row.soshikiidkankeis.forEach((value) => {
        if (value.soshikiId) {
          soshikiids.push(value.soshikiId);
        }
      });
    });
    setPageInfoCallAssign({
      spIds: Array.from(new Set(spids)),
      soshikiIds: Array.from(new Set(soshikiids)),
      prsIds: selectedRows.map((row) => row.prsId),
      prsNames: selectedRows.map((row) => row.name).join(','),
    });
    setAssignmentOpen(true);
  };

  const assignmentDialogClose = (backFlg: string) => {
    setPageInfoCallAssign(undefined);
    setAssignmentOpen(false);
    if (backFlg === 'finish') {
      navigate(routerPaths.app.kanri.supporters);
    }
  };

  const handleRowSelectionModelChange = (newSelectionModel: GridRowSelectionModel) => {
    setSelectionModel(newSelectionModel);
  };

  const columns: GridColDef<PrsGridRow>[] = useMemo(
    () => [
      { field: 'id', headerName: 'ID', width: 90, hide: true },
      {
        field: 'name',
        headerName: '氏名',
        width: 150,
        disableColumnMenu: true,
        sortable: true,
      },
      {
        field: 'memo',
        headerName: 'メモ',
        width: 80,
        minWidth: 80,
        disableColumnMenu: true,
        sortable: true,
        renderCell: (params: GridRenderCellParams<PrsGridRow, string>) => {
          return params.row.prsMemo ? (
            <Stack height="100%" alignItems="center" justifyContent="center">
              <Tooltip title={params.row.prsMemo} arrow>
                <MenuBookIcon fontSize="small" htmlColor={iconColorGrey} />
              </Tooltip>
            </Stack>
          ) : (
            appConst.STR_EMPTY
          );
        },
      },
      {
        field: 'agreement',
        headerName: '同意',
        renderHeader: () => (
          <Tooltip title="同意済は空白で表示されます" placement="top" arrow>
            <Box>同意</Box>
          </Tooltip>
        ),
        width: 80,
        minWidth: 80,
        disableColumnMenu: true,
        sortable: true,
        renderCell: (params: GridRenderCellParams<PrsGridRow, boolean>) =>
          params.value ? appConst.STR_EMPTY : appConst.LABEL_UNAGREED,
      },
      {
        field: 'prsstatus',
        headerName: '安否状況',
        width: 200,
        disableColumnMenu: true,
        sortable: true,
        renderCell: (params: GridRenderCellParams<PrsGridRow, boolean>) => {
          const prsActionType =
            params.row.lastVisitedAt && params.row.lastVisitedAt === params.row.lastPrsStatusUpdatedAt
              ? 'visit'
              : params.row.lastEvacuatedAt && params.row.lastEvacuatedAt === params.row.lastPrsStatusUpdatedAt
              ? 'evacuate'
              : undefined;
          return (
            <Stack height="100%" direction="row" alignItems="center" justifyContent="space-between">
              <Box>
                <StatusChip
                  prsStatusId={params.row.prsStatusId}
                  label={params.row.prsStatusName}
                  color={params.row.prsStatusColor as StatusColor}
                />
              </Box>
              {prsActionType && <PrsActionChip prsActionType={prsActionType} />}
            </Stack>
          );
        },
      },
      {
        field: 'soshiki',
        headerName: '所属地域',
        width: 200,
        minWidth: 200,
        flex: 1,
        disableColumnMenu: true,
        sortable: true,
        renderCell: (params: GridRenderCellParams<PrsGridRow, string>) => params.row.soshikiNames,
      },
      {
        field: 'action',
        headerName: appConst.STR_EMPTY,
        disableColumnMenu: true,
        sortable: false,
        width: 90,
        minWidth: 90,
        maxWidth: 90,
        renderCell: (params: GridRenderCellParams<PrsGridRow, string>) => (
          <Button
            fullWidth
            onClick={() => {
              detailButtonOnClick(params.row.prsId);
            }}
          >
            詳細
          </Button>
        ),
      },
    ],
    []
  );

  // useEffect
  useEffect(() => {
    if (userData) {
      getSystemStatus({ jichitaiid: userData.jichitaiId })
        .then((response) => {
          if (response.data) {
            setAdisplayFlg(response.data.systemStatus.adisplayflg);
          }
        })
        .catch(() => navigateSystemError());
    }
  }, [userData]);

  useEffect(() => {
    if (searchError.isError) {
      if (![axios.AxiosError.ERR_CANCELED, axios.AxiosError.ECONNABORTED].includes(searchError.reason ?? '')) {
        navigateSystemError();
      } else if (searchError.reason === axios.AxiosError.ECONNABORTED) {
        const snackbarMessage = snackbarMessages[SnackbarMessageId.SearchTimeOut];

        if (snackbarMessage) {
          enqueueSnackbar(snackbarMessage.message, {
            variant: snackbarMessage.variant,
          });
        }
      }
    }
  }, [searchError]);

  return (
    <PageContainer title="要支援者選択 | mutual-assistance">
      <KanriPageContainer title="変更する要支援者の選択">
        <Stack spacing={2} sx={{ overflow: 'auto', height: '100%' }}>
          <Stack spacing={2}>
            <Typography>要支援者（対象者）を選択してください</Typography>
          </Stack>
          <Stack flexGrow={1} sx={{ overflow: 'auto' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              rowCount={rowCount}
              loading={!!isLoading}
              disableRowSelectionOnClick
              paginationModel={paginationModel}
              paginationMode="server"
              onPaginationModelChange={setPaginationModel}
              sortingMode="server"
              sortModel={sortModel}
              onSortModelChange={onSortModelChange}
              columnVisibilityModel={{ id: false, agreement: adisplayFlg }}
              checkboxSelection
              disableMultipleRowSelection
              onRowSelectionModelChange={handleRowSelectionModelChange}
              hideFooter
              slotProps={{
                loadingOverlay: {
                  variant: 'circular-progress',
                  noRowsVariant: 'skeleton',
                },
                ...(isLoading === 'user'
                  ? {
                      pagination: {
                        backIconButtonProps: {
                          disabled: true,
                        },
                        nextIconButtonProps: {
                          disabled: true,
                        },
                      },
                    }
                  : {}),
              }}
            />
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            <StackSpacer />
            <Box>
              <Button variant="outlined" fullWidth={true} onClick={() => navigate(routerPaths.app.kanri.supporters)}>
                戻る
              </Button>
            </Box>
            <Box>
              <Button
                variant="contained"
                disabled={selectionModel?.length === 0}
                fullWidth={true}
                onClick={changeAssignmentButtonOnClick}
              >
                担当を変更する
              </Button>
            </Box>
          </Stack>
        </Stack>
      </KanriPageContainer>
      <SupportRecipientDialog
        prsId={selectedPrsId}
        open={supportRecipientDialogOpen}
        onClose={() => {
          setSupportRecipientDialogOpen(false);
          setSelectedPrsId(undefined);
        }}
        onError={() => navigateSystemError()}
      />
      <AssignSupportersSoshikiDialog
        pageInfoCallAssign={pageInfoCallAssign}
        isOpen={assignmentOpen}
        onClose={(backFlg: string) => assignmentDialogClose(backFlg)}
      />
    </PageContainer>
  );
};
