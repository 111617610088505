import { useMsal } from '@azure/msal-react';
import { Button, Container, Link, Stack, useTheme } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageContainer } from 'src/components/common/atoms/PageContainer';
import { AppList } from 'src/components/shien/atoms/AppList';
import { ConfirmDialog } from 'src/components/shien/atoms/dialogs/ConfirmDialog';
import { ShienProfileTemplate } from 'src/components/shien/pages/SupporterProfilePage/components/ShienProfileTemplate';
import { useSoshikiLeadersSectionDataList } from 'src/components/shien/pages/SupporterProfilePage/hooks/useSoshikiLeadersSectionDataList';
import { useSpSectionDataList } from 'src/components/shien/pages/SupporterProfilePage/hooks/useSpSectionDataList';
import { useNavigateSystemError } from 'src/hooks/common/useNavigateSystemError';
import { useUserData } from 'src/hooks/common/useUserData';
import { logout } from 'src/utilities/azure/signout';

export const SupporterProfilePage = () => {
  const { instance: pca } = useMsal();
  const theme = useTheme();
  const navigate = useNavigate();
  const navigateSystemError = useNavigateSystemError();

  // state
  const { userData } = useUserData();
  const [confirmCallDialogOpen, setConfirmCallDialogOpen] = useState(false);
  const [confirmCallDialogMessage, setConfirmCallDialogMessage] = useState<ReactNode>();
  const [callPhoneNumber, setCallPhoneNumber] = useState<string | undefined>();

  // custom hooks
  const {
    sectionDataList: spSectionDataList,
    getSectionDataList: getSpSectionDataList,
    searchCompleted: spSearchCompleted,
    soshikiIdList,
    lineIntegrationError,
  } = useSpSectionDataList();
  const { sectionDataList: soshikiLeadersSectionDataList, getSectionDataList: getSoshikiLeadersSectionDataList } =
    useSoshikiLeadersSectionDataList((phoneNumber, message) => {
      setCallPhoneNumber(phoneNumber);
      setConfirmCallDialogMessage(message);
      setConfirmCallDialogOpen(true);
    });

  // useEffect
  useEffect(() => {
    if (lineIntegrationError) {
      navigateSystemError();
    }
  }, [lineIntegrationError]);

  useEffect(() => {
    if (userData) {
      // 支援者の基本情報を取得
      getSpSectionDataList(userData.userId).catch(() => navigateSystemError());
    }
  }, [userData]);

  useEffect(() => {
    if (userData && spSearchCompleted) {
      getSoshikiLeadersSectionDataList(soshikiIdList).catch(() => navigateSystemError());
    }
  }, [userData, spSearchCompleted, soshikiIdList]);

  // handler
  const handleLogout = () => {
    logout(pca)
      .then(() => {
        navigate('/');
      })
      .catch(() => {
        navigateSystemError();
      });
  };

  return (
    <PageContainer title="あなたについて | mutual-assistance">
      <ShienProfileTemplate headlineText="あなたについて">
        <Stack sx={{ height: '100%', backgroundColor: theme.palette.uiStandard.gray10 }}>
          <AppList sectionDataList={[...spSectionDataList, ...soshikiLeadersSectionDataList]}>
            <Container sx={{ my: theme.spacing(1) }}>
              <Button
                variant="secondary"
                fullWidth
                sx={{ color: theme.palette.uiStandard.red50 }}
                onClick={handleLogout}
              >
                ログアウト
              </Button>
            </Container>
          </AppList>
        </Stack>
      </ShienProfileTemplate>
      <ConfirmDialog
        title="電話をかける"
        message={
          <>
            {confirmCallDialogMessage}
            <br />
            {callPhoneNumber}
          </>
        }
        positiveButtonProps={Object.assign(
          { children: 'かける' },
          callPhoneNumber && { component: Link, href: `tel:${callPhoneNumber}` }
        )}
        negativeButtonProps={{ children: 'かけない' }}
        open={confirmCallDialogOpen}
        onClose={() => {
          setConfirmCallDialogOpen(false);
          setCallPhoneNumber(undefined);
        }}
        onConfirm={() => {
          setConfirmCallDialogOpen(false);
          setCallPhoneNumber(undefined);
        }}
      />
    </PageContainer>
  );
};
