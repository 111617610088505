import { AccountInfo } from '@azure/msal-common';
import { globalDataStore, loginKindAtom } from 'src/jotai/atom';
import { LoginKind } from 'src/utilities/auth/loginKind';
import { initialize as initializePca } from 'src/utilities/azure/publicClientApplicationUtil';

export type LoginAccount =
  | {
      loginKind: LoginKind;
      isLoggedIn: false;
    }
  | {
      loginKind: LoginKind;
      isLoggedIn: true;
      idToken: string;
    };

export const getLoginAccount = async (): Promise<null | LoginAccount> => {
  const loginKind = globalDataStore.get(loginKindAtom);
  if (loginKind === undefined) {
    return null;
  }

  switch (loginKind) {
    case 'aadb2c': {
      const pca = await initializePca();
      const account = pca.getAllAccounts()[0] as undefined | AccountInfo;
      if (account === undefined) {
        return {
          loginKind,
          isLoggedIn: false,
        };
      }
      return {
        loginKind,
        isLoggedIn: true,
        idToken: account.idToken!,
      };
    }
    case 'line':
      // TODO: LINEログイン機能実装時に実装
      throw new Error('Not implemented yet');
  }
};
