import { Container, Link, Stack, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppSkeleton } from 'src/components/common/atoms/AppSkeleton';
import { PageContainer } from 'src/components/common/atoms/PageContainer';
import { ActionMenuButton } from 'src/components/shien/atoms/buttons/ActionMenuButton';
import { LargeStatusButton } from 'src/components/shien/atoms/buttons/SplitButton/LargeStatusButton';
import { ConfirmDialog } from 'src/components/shien/atoms/dialogs/ConfirmDialog';
import { IconCaution } from 'src/components/shien/atoms/images/IconCaution';
import { IconHome } from 'src/components/shien/atoms/images/IconHome';
import { IconPhone } from 'src/components/shien/atoms/images/IconPhone';
import { IconRun } from 'src/components/shien/atoms/images/IconRun';
import { PrsContext } from 'src/components/shien/pages/prs/PrsContextTemplate';
import { HeaderWithBackButtonTemplate } from 'src/components/shien/templates/HeaderWithBackButtonTemplate';
import { appConst, sexCodes } from 'src/constants/common';
import { useNavigateSystemError } from 'src/hooks/common/useNavigateSystemError';
import { StatusColor } from 'src/types/common/statusColor';
import { determineShelterName } from 'src/utilities/appUtil/commonUtil';
import { determinePrsActionType } from 'src/utilities/appUtil/shienUtil';
import { calculateCurrentAge, shortTimeFormat } from 'src/utilities/dateUtil';
import { patchPrsDetail } from 'src/utilities/restApi/common/prsDetail';

type PageData = {
  prsId: string;
  prsName: string;
  sexLabel: string;
  age: number;
  ageLabel: string;
  telNo?: string;
  prsStatusId: string;
  prsStatusName: string;
  prsStatusColor: StatusColor | 'none';
  shelterName: string;
  prsMemo?: string;
  lastPrsStatusUpdatedAt?: string;
  lastCalledAt: string;
  lastVisitedAt: string;
  lastEvacuatedAt: string;
};

export const PrsSummaryPage = () => {
  // context
  const { prs, setPrs, refreshData, initialized } = useContext(PrsContext);

  // navigation
  const navigate = useNavigate();
  const navigateSystemError = useNavigateSystemError();

  // states
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState<PageData>();
  const [confirmCallDialogOpen, setConfirmCallDialogOpen] = useState(false);

  useEffect(() => {
    if (initialized) {
      setLoading(false);
    }
  }, [initialized]);

  useEffect(() => {
    if (!loading) {
      if (prs) {
        // contextに設定
        setPrs(prs);

        const age = calculateCurrentAge(prs.birthday)!;
        const pageData: PageData = {
          prsId: prs.prsId,
          prsName: `${prs.fName} ${prs.sName}`,
          sexLabel: prs.sex ? sexCodes.find((x) => x.code === prs.sex)!.Label : '',
          age,
          ageLabel: age ? `${age}歳` : '',
          telNo: prs.telNo,
          prsStatusId: prs.prsStatusId,
          prsStatusName: prs.prsStatusName,
          prsStatusColor: prs.prsStatusColor ?? 'none',
          shelterName: prs.shelterName ?? '',
          prsMemo: prs.prsMemo,
          lastPrsStatusUpdatedAt: shortTimeFormat(prs.lastPrsStatusUpdatedAt),
          lastCalledAt: shortTimeFormat(prs.lastCalledAt),
          lastVisitedAt: shortTimeFormat(prs.lastVisitedAt),
          lastEvacuatedAt: shortTimeFormat(prs.lastEvacuatedAt),
        };
        setPageData(pageData);
      } else {
        navigateSystemError();
      }
    }
  }, [loading, prs]);

  const handleConfirm = async () => {
    setConfirmCallDialogOpen(false);
    if (pageData?.prsId) {
      try {
        await patchPrsDetail({ prsid: prs?.prsId || '', lastcalledat: true });
        await refreshData();
      } catch {
        navigateSystemError();
      }
    }
  };

  return (
    <PageContainer title="支援ガイド | mutual-assistance">
      <HeaderWithBackButtonTemplate
        headlineText={loading || !pageData ? <AppSkeleton width="200px" /> : `${pageData.prsName}`}
        lowerText={loading || !pageData ? <AppSkeleton width="100px" /> : `${pageData.sexLabel} ${pageData.ageLabel}`}
        onDetailClick={() => navigate('/app/shien/prs/detail')}
        onBackClick={() => navigate('/app/shien/prs-list')}
      >
        <Container sx={{ py: 1, height: '100%' }}>
          {loading || !pageData ? (
            <Stack>
              <AppSkeleton variant="card" height="180px" />
              <Stack spacing={2} sx={{ mt: 2, mb: 2 }}>
                <AppSkeleton variant="card" height={'100px'} />
                <AppSkeleton variant="card" height={'100px'} />
                <AppSkeleton variant="card" height={'100px'} />
              </Stack>
            </Stack>
          ) : (
            <Stack>
              <LargeStatusButton
                statusText={pageData.prsStatusName ?? ''}
                prsActionType={
                  prs &&
                  determinePrsActionType(
                    prs.lastPrsStatusUpdatedAt ?? '',
                    prs.lastVisitedAt ?? '',
                    prs.lastEvacuatedAt ?? ''
                  )
                }
                icon={pageData.prsStatusId === appConst.ID_PRS_STATUTS_UNCHECKED && <IconCaution />}
                upperColor={pageData.prsStatusColor}
                shelterName={determineShelterName(
                  prs?.prsStatusId ?? '',
                  prs?.shelterTypeCode ?? '',
                  prs?.shelterName ?? '',
                  prs?.customShelterName ?? ''
                )}
                memoText={pageData.prsMemo ?? 'メモなし'}
                lastUpdateTime={pageData.lastPrsStatusUpdatedAt}
                buttonText="安否を登録する"
                onClick={() => navigate('/app/shien/prs/safety-summary')}
                fullWidth
              />

              <Typography variant="body1" sx={{ mt: 2 }}>
                安否の確認方法を選んでください。
              </Typography>
              <Stack spacing={2} sx={{ mb: 2 }}>
                <ActionMenuButton
                  icon={<IconPhone />}
                  title="電話を掛ける"
                  description="対象者に電話をかけ、安否を確認しましょう"
                  time={pageData.lastCalledAt}
                  onClick={() => setConfirmCallDialogOpen(true)}
                  disabled={!pageData.telNo}
                />
                <ActionMenuButton
                  icon={<IconHome />}
                  title="自宅に訪問する"
                  description="地図を確認し、対象者の自宅に向かいましょう"
                  time={pageData.lastVisitedAt}
                  onClick={() => navigate('/app/shien/prs/action/visit')}
                />
                <ActionMenuButton
                  icon={<IconRun />}
                  title="避難先に同行する"
                  description="地図を確認し、対象者と一緒に避難先に向かいましょう"
                  time={pageData.lastEvacuatedAt}
                  onClick={() => navigate('/app/shien/prs/action/evacuate')}
                />
              </Stack>
            </Stack>
          )}
        </Container>
      </HeaderWithBackButtonTemplate>
      <ConfirmDialog
        title="電話をかける"
        message="対象者に電話をかけ、安否や避難先などを確認しましょう。"
        positiveButtonProps={Object.assign(
          { children: 'かける' },
          pageData?.telNo && { component: Link, href: `tel:${pageData.telNo}` }
        )}
        negativeButtonProps={{ children: 'かけない' }}
        open={confirmCallDialogOpen}
        onClose={() => setConfirmCallDialogOpen(false)}
        onConfirm={handleConfirm}
      />
    </PageContainer>
  );
};
