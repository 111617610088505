import { AxiosError, AxiosRequestConfig } from 'axios';
import { restApi } from 'src/utilities/restApi';
import { ErrorResponse, SuccessResponse } from 'src/utilities/restApi/common/types/responses';
import {
  RequestDeleteJianSoshiki,
  RequestGetJianSoshiki,
  RequestPostJianSoshiki,
  ResponseDeleteJianSoshiki,
  ResponseGetJianSoshiki,
  ResponsePostJianSoshiki,
} from 'src/utilities/restApi/kanri/types/jianSoshiki';
import { getCommonHeaders } from 'src/utilities/restApi/utilities/getCommonHeader';

const url = '/jian-soshiki';

export const getJianSoshiki = async (params: RequestGetJianSoshiki) => {
  const headers = { headers: await getCommonHeaders() };
  const config = { ...headers, params };

  return new Promise<SuccessResponse<ResponseGetJianSoshiki>>((resolve, reject) => {
    restApi()
      .get<SuccessResponse<ResponseGetJianSoshiki>>(url, config)
      .then((response) => {
        resolve(response.data!);
      })
      .catch((error: AxiosError<ErrorResponse>) => {
        reject(error.response?.data);
      });
  });
};

const TIMEOUT = 60000;

export const postJianSoshiki = async (data: RequestPostJianSoshiki) => {
  const headers = { headers: await getCommonHeaders() };
  const config: AxiosRequestConfig = { ...headers };

  return new Promise<SuccessResponse<ResponsePostJianSoshiki>>((resolve, reject) => {
    restApi(TIMEOUT)
      .post<SuccessResponse<ResponsePostJianSoshiki>>(url, data, config)
      .then((response) => {
        resolve(response.data!);
      })
      .catch((error: AxiosError<ErrorResponse>) => {
        return reject(error.response?.data);
      });
  });
};

export const deleteJianSoshiki = async (params: RequestDeleteJianSoshiki) => {
  const headers = { headers: await getCommonHeaders() };
  const config = { ...headers, params };

  return new Promise<SuccessResponse<ResponseDeleteJianSoshiki>>((resolve, reject) => {
    restApi(TIMEOUT)
      .delete<SuccessResponse<ResponseDeleteJianSoshiki>>(url, config)
      .then((response) => {
        resolve(response.data!);
      })
      .catch((error: AxiosError<ErrorResponse>) => {
        reject(error.response?.data);
      });
  });
};
