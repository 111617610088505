import { useAtom } from 'jotai';
import { useEffect, useRef } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { RoleId } from 'src/constants/common';
import { useNavigateSystemError } from 'src/hooks/common/useNavigateSystemError';
import { globalDataStore, jichitaiDataAtom, userDataAtom, userIdAtom } from 'src/jotai/atom';
import { routerPaths } from 'src/routes/path';
import { loginWithRedirect } from 'src/utilities/auth/login';
import { getLoginAccount } from 'src/utilities/auth/loginAccount';
import { getUser } from 'src/utilities/restApi/common/users';
import { getJichitai } from 'src/utilities/restApi/shien/jichitais';
import { getPathWithParams } from 'src/utilities/urlUtil';

export const AuthAreaTemplate = () => {
  const navigate = useNavigate();
  const navigateSystemError = useNavigateSystemError();

  const [userId] = useAtom(userIdAtom, { store: globalDataStore });
  const [userData, setUserData] = useAtom(userDataAtom, { store: globalDataStore });
  const [jichitaiData, setJichitaiData] = useAtom(jichitaiDataAtom, { store: globalDataStore });

  const isInitialMountRef = useRef(true);

  useEffect(() => {
    if (userId == null) {
      navigate(routerPaths.index);
      return;
    }

    if (userId) {
      getLoginAccount().then((loginAccount) => {
        if (loginAccount === null) {
          // ログイン未実施
          navigate(routerPaths.index);
          return;
        }
        if (!loginAccount.isLoggedIn) {
          // sessionStorageに認可なし (新規タブなど)
          if (isInitialMountRef.current) {
            isInitialMountRef.current = false;
            loginWithRedirect(loginAccount.loginKind, { afterPagePath: getPathWithParams() }).catch(() =>
              navigateSystemError()
            );
          }
          return;
        }
        // ログイン済み
        getUser({ userid: userId })
          .then((response) => {
            if (response.data && response.data.user) {
              const user = response.data.user;
              setUserData({
                userId: user.userid,
                fName: user.fname,
                sName: user.sname,
                kfName: user.kfname,
                ksName: user.ksname,
                telNo: user.telno ?? undefined,
                mailAddress: user.mailaddress ?? undefined,
                isLinkedAadb2cuserid: user.is_linked_aadb2cuserid,
                linkedAadb2cTypeCode: user.linked_aadb2c_type_code,
                isLinkedLineUserId: user.is_linked_lineuserid,
                roleId: (user.roleid as RoleId) ?? undefined,
                roleName: user.rolename ?? undefined,
                jichitaiId: user.jichitaiid,
                jichitaiName: user.jichitainame,
                soshikiids: user.soshikiids,
              });
            }
          })
          .catch(() => navigateSystemError());
      });
    }
  }, [userId]);

  useEffect(() => {
    if (userData && jichitaiData == null) {
      getJichitai({ jichitaiid: userData.jichitaiId })
        .then((response) => {
          if (response.data && response.data.jichitai) {
            const jichitai = response.data.jichitai;
            setJichitaiData({
              jichitaiId: jichitai.jichitaiid,
              evaJichitaiId: jichitai.evajichitaiid,
              name: jichitai.name,
              channelId: jichitai.channel_id,
            });
          }
        })
        .catch(() => navigateSystemError());
    }
  }, [userData, jichitaiData]);

  return <Outlet />;
};
