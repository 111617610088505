import { MsalProvider } from '@azure/msal-react';
import '@fontsource/noto-sans-jp';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { SnackbarProvider } from 'notistack';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { AppRouters } from 'src/routes/AppRouters';
import { PublicClientApplication } from 'src/utilities/azure/hooks/client';
import { setLocale } from 'yup';
import * as ja from 'yup-locale-ja';

function App() {
  setLocale(ja.suggestive);

  if (import.meta.env.VITE_ENV === 'prod') {
    // 本番環境の場合、コンソール出力を無効にする
    console.log = console.info = console.debug = console.warn = console.error = () => {};
  }

  return (
    <MsalProvider instance={PublicClientApplication.getInstance()}>
      <BrowserRouter>
        <SnackbarProvider
          maxSnack={5}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          style={{ position: 'relative' }}
        >
          <HelmetProvider>
            <AppRouters />
          </HelmetProvider>
        </SnackbarProvider>
      </BrowserRouter>
    </MsalProvider>
  );
}

export default App;
