import { RESET } from 'jotai/utils';
import { afterLoginPageAtom, globalDataStore, loginKindAtom } from 'src/jotai/atom';
import { LoginKind } from 'src/utilities/auth/loginKind';
import { PublicClientApplication } from 'src/utilities/azure/hooks/client';
import { loginWithRedirect as azureLoginWithRedirect } from 'src/utilities/azure/signin';

type LoginWithRedirectOpts = {
  /**
   * ユーザーに認証画面での資格情報入力を強制する
   * @default false
   */
  requirePrompt?: boolean;
  /**
   * リダイレクト後に遷移する画面
   * @default ロールに応じた初期画面
   */
  afterPagePath?: string;
};

export const loginWithRedirect = async (
  loginKind: LoginKind,
  { requirePrompt = false, afterPagePath }: LoginWithRedirectOpts = {}
): Promise<void> => {
  // ログイン種別を保持
  globalDataStore.set(loginKindAtom, loginKind);

  // afterPageが指定された場合はAzureCallbackPageでの初期画面遷移分岐を行わないようにする
  globalDataStore.set(afterLoginPageAtom, afterPagePath !== undefined ? afterPagePath : RESET);

  switch (loginKind) {
    case 'aadb2c': {
      const pca = PublicClientApplication.getInstance();
      await azureLoginWithRedirect(pca, { requirePrompt });
      break;
    }
    case 'line':
      // TODO: LINEログイン処理を実装
      break;
  }
};

/**
 * {@link loginWithRedirect()}時に`afterPage`が指定されていた場合はそのパスを消費して返す
 */
export const popAfterPage = () => {
  const afterPage = globalDataStore.get(afterLoginPageAtom);
  if (afterPage) {
    globalDataStore.set(afterLoginPageAtom, RESET);
  }
  return afterPage;
};
