import { GridRowsProp, GridSortModel } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { SearchConditions } from 'src/components/kanri/pages/jichitai/SearchSupportRecipientsPage/SearchConditionField/types';
import {
  GridRow,
  GridRowSupporter,
  PaginationModel,
} from 'src/components/kanri/pages/jichitai/SearchSupportRecipientsPage/types';
import { appConst } from 'src/constants/common';
import { useUserData } from 'src/hooks/common/useUserData';
import { SearchCallerType } from 'src/types/kanri/SearchCallerType';
import { SearchError } from 'src/types/kanri/SearchError';
import { ErrorResponse } from 'src/utilities/restApi/common/types/responses';
import { searchSupportRecipients } from 'src/utilities/restApi/kanri/search/searchSupportRecipients';

type PageInfo = {
  totalRowCount: number;
  hasNextPage: boolean;
  pageSize: number;
};

type HookParams = {
  paginationModel: PaginationModel;
  searchConditions: SearchConditions;
  sortModel?: GridSortModel;
};

export const useQuery = ({ paginationModel, searchConditions, sortModel }: HookParams) => {
  const [controller, setController] = useState<AbortController>();
  const { userData } = useUserData();
  const [isLoading, setIsLoading] = useState<SearchCallerType | undefined>('system');
  const [error, setError] = useState<SearchError>({ isError: false, reason: undefined });
  const [rows, setRows] = useState<GridRowsProp<GridRow>>([]);
  const [pageInfo, setPageInfo] = useState<PageInfo>({
    totalRowCount: 0,
    hasNextPage: false,
    pageSize: 0,
  });

  useEffect(() => {
    search('user');
  }, [userData, paginationModel, searchConditions, sortModel]);

  // functions
  const search = (callerType: SearchCallerType) => {
    if (userData) {
      controller?.abort();
      setIsLoading(callerType);
      const newController = new AbortController();
      setController(newController);
      searchSupportRecipients(
        {
          page: paginationModel.page,
          page_size: paginationModel.pageSize,
          search_conditions: {
            simple: searchConditions.simple
              ? {
                  prsstatusid: searchConditions.simple.prsStatusId || null,
                  soshikiid_list:
                    searchConditions.simple.soshikiList.length > 0
                      ? searchConditions.simple.soshikiList.map((x) => x.soshikiId)
                      : null,
                  registered_memo:
                    searchConditions.simple.registeredMemo === appConst.STR_EMPTY
                      ? null
                      : searchConditions.simple.registeredMemo === appConst.STR_TRUE,
                  agreement:
                    searchConditions.simple.agreement === appConst.STR_EMPTY
                      ? null
                      : searchConditions.simple.agreement === appConst.STR_TRUE,
                }
              : undefined,
            advanced: searchConditions.advanced
              ? {
                  prs_fname: searchConditions.advanced.prsFName || null,
                  prs_sname: searchConditions.advanced.prsSName || null,
                  prs_soshikiid:
                    !searchConditions.advanced.prsSoshiki ||
                    searchConditions.advanced.prsSoshiki?.soshikiId === appConst.STR_EMPTY
                      ? null
                      : searchConditions.advanced.prsSoshiki.soshikiId,
                  sp_fname: searchConditions.advanced.spFName || null,
                  sp_sname: searchConditions.advanced.spSName || null,
                  sp_soshikiid:
                    !searchConditions.advanced.spSoshiki ||
                    searchConditions.advanced.spSoshiki?.soshikiId === appConst.STR_EMPTY
                      ? null
                      : searchConditions.advanced.spSoshiki.soshikiId,
                }
              : undefined,
          },
          sort_model:
            sortModel && sortModel.length > 0
              ? {
                  field: sortModel[0].field,
                  sort: sortModel[0].sort ?? null,
                }
              : undefined,
        },
        newController
      )
        .then((response) => {
          if (response.data) {
            const gridRows: GridRowsProp<GridRow> = response.data.search_results.rows.map((row) => ({
              id: row.prsid,
              prsId: row.prsid,
              name: row.name,
              agreement: row.agreement ?? undefined,
              prsStatusChip: undefined,
              prsStatusId: row.prsstatusid,
              prsStatusName: row.prsstatusname,
              prsStatusColor: row.prsstatuscolor,
              prsStatusSortOrder: row.prsstatussortorder,
              prsMemo: row.prsmemo ?? undefined,
              lastPrsStatusUpdatedAt: row.lastprsstatusupdatedat ?? undefined,
              lastVisitedAt: row.lastvisitedat ?? undefined,
              lastEvacuatedAt: row.lastevacuatedat ?? undefined,
              supporters: row.supporters.map(
                (x) =>
                  ({
                    shienKankeiId: x.shienkankeiid,
                    spId: x.spid ?? undefined,
                    name: x.name ?? undefined,
                    spCheckId: x.spcheckid ?? undefined,
                    spCheckStatus: x.spcheckstatus ?? undefined,
                    soshikiId: x.soshikiid ?? undefined,
                    soshikiName: x.soshikiname ?? undefined,
                  } as GridRowSupporter)
              ),
              soshikiNames: row.soshikinames ?? undefined,
            }));
            setRows(gridRows);
            setPageInfo({
              totalRowCount: response.data.search_results.page_info.total_row_count,
              hasNextPage: response.data.search_results.page_info.has_next_page,
              pageSize: paginationModel.pageSize,
            });
          }
        })
        .catch((response: ErrorResponse) => {
          setError({ isError: true, reason: response.error.reason });
        })
        .finally(() => {
          // abort時は次のリクエストのローディングが走るので、ローディングを解除しない
          if (!newController.signal.aborted) {
            setIsLoading(undefined);
          }
        });
    }
  };

  return { isLoading, rows, pageInfo, error, search };
};
